// COLOR VARIABLES
$color-primary: rgb(255, 246, 239);
$color-primary-dark: rgb(196, 191, 186);

$color-secondary: #111010;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #aaa;

$color-grey-dark-1: #222222;
$color-grey-dark-2: #201e1e;
$color-white: white;
$color-black: black;
// FONT VARIABLES
$font-primary: "poppinsregular", sans-serif;
$font-display: "bebas_neueregular", sans-serif;

$color-onyx-grey: #353935;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px;
$bp-small: 37.5em; // 600px;
$box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
@tailwind base;
@tailwind components;
@tailwind utilities;

.box-shadow {
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media only screen and (max-width: $bp-largest) {
    font-size: 50%;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 45%;
  }
}

body {
  transition: all 0.7s;
  font-family: $font-primary;
  color: $color-primary;
  font-weight: 300;
  line-height: 1.6;
  background-color: $color-secondary;
  overscroll-behavior: none;
  // transform: scaleY(.5);
}
input[type="color" i]::-webkit-color-swatch-wrapper {
  padding: 4px;
  color: currentColor;
}

label {
  font-weight: bold;
  margin-right: 8px;
}
.wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  // background-color: red;
}

.scroll-arrow {
  grid-column: full-start / center-start;
  // width: 40%;
  // height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 10rem;

  background-color: red;
}
.container {
  position: relative;
  // padding: 10rem;
  // border-radius: 3.2rem;
  display: grid;
  z-index: 3;
  overflow: hidden;
  padding: 4rem;
  // grid-template-rows: 8rem 80vh repeat(4, min-content);
  grid-template-columns:
    [full-start] minmax(6rem, 1fr) [center-start] repeat(
      12,
      [col-start] minmax(0, 10rem) [col-end]
    )
    [center-end] minmax(6rem, 1fr) [full-end];

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns:
      [full-start]1fr [center-start] repeat(
        12,
        [col-start] minmax(0, 10rem) [col-end]
      )
      [center-end] 1fr [full-end];
    padding: 0px 10px;
  }
  @media only screen and (max-width: $bp-small) {
    grid-template-columns:
      [full-start]0 [center-start] repeat(
        12,
        [col-start] minmax(0, 10rem) [col-end]
      )
      [center-end] 0 [full-end];
    padding: 1.3rem;
  }
}

.btn-text {
  &:link,
  &:visited {
    font-size: 1.4rem;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
    padding: 3px;
    transition: all 0.2s;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-onyx-grey;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 0.5rem rgba($color-black, 0.15);
    transform: translateY();
  }
}
.resume-container {
  font-family: $font-primary;
  padding: 16px;

  overflow-x: hidden !important;
  color: currentColor;
  // border-radius: 1.5rem;
  // -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  // -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  // box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  // border-radius: 16px;
}

.year {
  color: currentColor;
  font-weight: 200;
  font-style: italic;
  font-size: 2rem;
  font-family: $font-display;
}

.title {
  font-size: 4rem;
  font-family: $font-display;
  margin-bottom: 8px;
}

.description {
  font-family: $font-primary;
  margin-top: 16px;
}

.skills {
  margin-top: 16px;
  display: flex;

  gap: 8px;
}

.skills span {
  color: currentColor;
  padding: 4px 8px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}
