%heading {
    font-family: $font-display;
    font-weight: 400;
}

.heading-1 {
    @extend %heading;
    font-size: 20rem;
    // color: $color-grey-light-1;
    line-height: .7;

    @media only screen and (max-width: $bp-largest) {
        font-size: 14rem;

    }

    @media only screen and (max-width: $bp-medium) {
        font-size: 13rem;

    }

    @media only screen and (max-width: $bp-small) {
        font-size: 10rem;

    }

}

.heading-2 {
    @extend %heading;
    font-size: 4rem;
    // font-style: italic;
    line-height: 1;
    margin-top: 1rem;

    &--light {
        color: $color-grey-light-1;
    }

    &--dark {
        color: $color-grey-dark-1;
    }
}

.heading-3 {
    // @extend %heading;
    font-size: 1.6rem;
    color: $color-primary;
    font-style: italic;
    text-transform: uppercase;
}

.heading-4 {
    @extend %heading;
    font-size: 1.9rem;

    &--light {
        color: $color-grey-light-1;
    }

    &--dark {
        color: $color-grey-dark-1;
    }
}

.btn {
    background-color: $color-primary;
    color: #fff;
    border: none;
    border-radius: 0;
    font-family: $font-display;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.8rem 3rem;
    cursor: pointer;
    transition: all .2s;

    &:hover {
        background-color: $color-primary-dark;
    }
}

.mb-sm {
    margin-bottom: 2rem;
}

.mb-md {
    margin-bottom: 3rem;
}

.mb-lg {
    margin-bottom: 4rem;
}

.mb-hg {
    margin-bottom: 8rem;
}