.about-me {
  grid-column: center-start / center-end;
  display: grid;
  grid-template-rows: auto;
  // margin-bottom: 4rem;
  margin-top: 50vh;
  position: relative;
  height: 100%;
  // background-color: red;

  p {
    font-weight: 400;
    padding: 1rem;
    font-size: 2rem;
    text-align: start;
  }

  &__header {
    font-family: $font-display;
    font-size: 12rem;
    background-color: red;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1.5rem;
    position: relative;
    // align-items: center;

    // &::after {

    //   content: "";
    //   height: 1px;
    //   display: block;
    //   background-color: currentColor;
    // }
  }
}
