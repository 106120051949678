.header-top {
  grid-column: center-start / center-end;
  // grid-row: 1 / 2;
  height: 10rem;
  font-family: $font-display;
  // max-width: 375px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  position: relative;
  font-size: 10rem;
  // padding: .5rem;  ADD MEDIA Q
  border-bottom: 1px solid $color-grey-light-2;

  &__name {
    min-height: 4rem;
    text-transform: uppercase;
    width: 100%;
    font-size: 5.4rem;
    align-self: center;
    @media only screen and (max-width: $bp-small) {
      font-size: 3.4rem;
    }
  }

  &__menu {
    cursor: pointer;
    text-transform: uppercase;
    align-self: center;
  }
}

.header {
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  // background-color: $color-primary;

  display: grid;
  grid-template-rows:
    minmax(10vh, 20vh) repeat(auto-fill, minmax(10vh, 1fr)) minmax(
      5vh,
      max-content
    )
    minmax(10vh, 20vh);
  grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));

  &__title {
    font-family: $font-display;

    grid-column: 2 / -1;
    grid-row: 2 / span 1;

    align-self: start;
  }

  &__sub-title {
    font-family: $font-display;

    grid-column: 2 / -1;
    grid-row: 4 / span 1;
  }

  &__contact {
    grid-row: -1;
    grid-column: -9 / -1;
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // background: yellow;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    white-space: nowrap;
    transform: translateY(-50%);
    // align-self: center;

    transition: all 0.7s;

    background-color: transparent;

    @media only screen and (max-width: $bp-small) {
      grid-column: 1;
    }
  }
}

// .contact-active {
//     background-color: blue;

//     transform: translate(-100%, -50%) rotate(-25deg) scale3d(1, 1, 1);
// }

.get-in-touch {
  // animation-name: spin;
  // animation-duration: 5000ms;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;
  // position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // transition: all .2s;
  //   background: black;

  // background: red;

  &__github {
    // background-image: url(/img/github-mark.png);
    fill: currentColor;
    // background-size: cover;
    // position: absolute;
    // top: 0;
    // left: 0%;h
    // width: 100%;
    max-height: 80px;
    transition: all 0.7s;
    animation: 6s 2s shake infinite;
    animation-play-state: running;
    position: relative;
    padding: 6px 3px;

    &__text {
      svg {
        fill: currentColor;
        // background-size: cover;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        transition: all 0.7s;
        transform: scale(0.8) rotate(280deg);
      }
    }

    &:hover > &__text > svg {
      opacity: 1;
      transform: scale(1.4) rotate(0);
    }

    &:hover {
      animation-play-state: paused;
    }
  }

  &__linkedin {
    // background-image: url(/img/github-mark.png);
    fill: currentColor;
    // background-size: cover;
    position: relative;

    // top: 0;
    // right: 0;
    // width: 10rem;
    // height: 10rem;
    transition: all 0.7s;
    animation: 6s 2s shake infinite;
    animation-play-state: running;

    &:hover {
      animation-play-state: paused;
    }

    &:hover > &__text > svg {
      transform: scale(1.2) rotate(0);
    }

    &__text {
      svg {
        fill: currentColor;
        // background-size: cover;
        position: absolute;

        top: 0;
        left: 0;
        transition: transform 0.7s, color 0s;
        transform: scale(0.8) rotate(280deg);
      }
    }
  }

  &__email {
    // background-image: url(/img/github-mark.png);
    fill: currentColor;
    // background-size: cover;
    // position: absolute;
    // top: 0;
    // left: 0%;h
    // width: 100;
    // max-height: 80px;
    transition: all 0.7s;
    position: relative;
    // padding: 6px 3px;
    animation: 6s 2s shake infinite;
    animation-play-state: running;

    &:hover {
      animation-play-state: paused;
    }

    &__text {
      svg {
        fill: currentColor;
        // background-size: cover;
        position: absolute;

        opacity: 0;
        top: 0;
        right: 0;
        transition: all 0.7s;
        transform: scale(0.8) rotate(280deg);
      }
    }

    &:hover > &__text > svg {
      opacity: 1;
      transform: scale(1.4) rotate(0);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }

  25% {
    transform: rotate(-45deg) scale(1.3);
  }

  50% {
    transform: rotate(0deg) scale(1.5);
  }

  75% {
    transform: rotate(45deg) scale(1.5);
  }

  75% {
    transform: rotate(-45deg) scale(1.3);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  // 20% {
  //     transform: translate(-3px, 0px) rotate(1deg);
  // }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(0, 0) rotate(0);
  }

  69% {
    transform: translate(0, 0) rotate(0);
  }

  // 70% {
  //     transform: translate(3px, 1px) rotate(-1deg);
  // }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
