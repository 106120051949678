.footer {
  background-color: $color-secondary;
  // grid-column: full-start/full-end;
  grid-column: center-start / center-end;
  padding: 10rem;
  @media only screen and (max-width: $bp-small) {
    grid-column: full-start / full-end;
  }
}

.nav {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 2rem;
  align-items: center;

  &__link:link,
  &__link:visited {
    font-size: 3rem;
    color: $color-primary;
    text-decoration: none;
    font-family: $font-display;
    text-transform: uppercase;
    text-align: center;
    display: block;
    // background-color: rgba(white, .05);
    transition: all 0.2s;
  }

  &__link:hover,
  &__link:active {
    background-color: rgba(white, 0.05);
    transform: translateY(-3px) scale(1.3);
  }
}

.copyright {
  font-size: 1.4rem;
  color: $color-primary;
  margin-top: 6rem;
  text-align: center;
  width: 100%;
}
