// .npmpackage-section {
//   margin-top: 5rem;

//   grid-column: center-start / center-end;
//   margin-top: 1rem;
//   // background-color: $color-onyx-grey;

//   // row-gap: 1rem;
//   padding: 2rem 4rem;
//   display: grid;
//   //   align-content: center;
//   //   justify-items: start;
//   font-size: 2rem;
//   row-gap: 1rem;
//   // overflow: hidden;
//   color: currentColor;
//   -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
//   -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
//   box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
//   border-radius: 16px;
//   overflow-x: hidden !important;
//   // color: $color-primary;
//   // -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
//   // -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
//   // box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);

//   @media only screen and (max-width: $bp-small) {
//   }

//   &__select-or-slide {
//     display: grid;
//     row-gap: 1rem;
//     grid-template-columns: 1fr min-content 1fr;
//     font-family: $font-display;
//     align-content: center;
//     justify-items: center;
//     column-gap: 1.3rem;
//     &__left {
//       display: flex;
//       // background-color: aqua;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//     }
//     &__mid {
//       font-size: medium;
//       display: grid;
//       grid-template-rows: 1fr min-content 1fr;
//       justify-items: center;
//       &--top {
//         width: 2px;
//         background: currentColor;
//       }
//       &--bottom {
//         width: 2px;
//         background: currentColor;
//       }
//     }
//     &__right {
//       display: flex;
//       // background-color: aqua;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }
.npmpackage-section {
  margin-top: 5rem;
  grid-column: center-start / center-end;
  padding: 2rem 4rem;
  display: grid;
  font-size: 2rem;
  row-gap: 1rem;
  color: currentColor;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  overflow-x: auto;
  overflow-y: hidden;

  @media only screen and (max-width: $bp-small) {
    // Add responsive styles here if required
  }

  &__select-or-slide {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 1fr min-content 1fr;
    font-family: $font-display;
    align-content: center;
    justify-items: center;
    column-gap: 1.3rem;

    &__left,
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__mid {
      font-size: medium;
      display: grid;
      grid-template-rows: 1fr min-content 1fr;
      justify-items: center;

      &--top,
      &--bottom {
        width: 2px;
        background: currentColor;
      }
    }
  }
}
