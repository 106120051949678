.projects {
  grid-column: center-start / center-end;
  // grid-column: col-start 1 / col-end 12;
  // background-color: pink;
  display: flex;
  flex-direction: column;

  &__header {
    font-family: $font-display;

    font-size: 12rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 1.5rem;
    align-items: center;

    @media only screen and (max-width: $bp-small) {
      font-size: 5rem;
    }

    &::before {
      content: "";
      height: 1px;
      display: block;
      background-color: currentColor;
    }
  }
}

.project {
  margin-top: 5rem;

  grid-column: center-start / center-end;

  display: grid;
  font-size: 2rem;

  // grid-template-rows: repeat(6, 1fr);
  grid-template-columns: 48% 4% 48%;

  // column-gap: 5rem;

  @media only screen and (max-width: $bp-small) {
    grid-template-columns: 50% 50%;
  }

  &__group {
    grid-column: 1 / span 1;

    display: flex;
    flex-direction: column;

    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;
    transition: all 0.9s;

    @media only screen and (max-width: $bp-small) {
      grid-column: 1/-1;
      // min-height: 50vh;
    }

    &__img {
      // flex-grow: .5;
      padding: 2rem 2rem;

      cursor: pointer;
      align-self: stretch;
      transition: all 0.9s;
      transform: scale(0.8);
      -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
      -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
      box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
      border-radius: 16px;
      border-radius: 16px;
      width: 100%;
      height: 130%;
      object-fit: contain;
      // border: 2px solid $color-secondary;
      display: block;
      position: absolute;
      border-radius: 16px;
      object-fit: contain;
      @media only screen and (max-width: $bp-small) {
        padding: 5rem 2rem;
      }

      &--zoom--poke {
        background-image: url(/img/Pokemon-binder-home.png);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 16px;
        left: 0px;
        top: 0px;
        background-size: 300%;
        background-position: 0% 0%;

        // filter: sepia(100%) hue-rotate(160deg);
        opacity: 0.8;
        animation: pan-image 15s ease-in-out infinite;
      }

      &--zoom--saiz {
        background-image: url(/img/longsaiz.png);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 16px;
        left: 0px;
        top: 0px;
        background-size: 300%;
        background-position: 0% 0%;

        // filter: sepia(100%) hue-rotate(160deg);
        opacity: 0.8;
        animation: pan-image 20s ease-in-out infinite;
      }

      &--zoom--trivia {
        background-image: url(/img/TriviaZoom.png);

        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 16px;
        left: 0px;
        top: 0px;
        background-size: 300%;
        background-position: 0% 0%;

        // filter: sepia(100%) hue-rotate(160deg);
        opacity: 0.8;
        animation: pan-image 20s ease-in-out infinite;
      }

      &--zoom--likey {
        background-image: url(/img/likeyZoom.png);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 16px;
        left: 0px;
        top: 0px;
        background-size: 300%;
        background-position: 0% 0%;

        // filter: sepia(100%) hue-rotate(160deg);
        opacity: 0.8;
        animation: pan-image 20s ease-in-out infinite;
      }

      &--zoom--fyur {
        background-image: url(/img/FyurZoom.png);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 16px;
        left: 0px;
        top: 0px;
        background-size: 300%;
        background-position: 0% 0%;

        // filter: sepia(100%) hue-rotate(160deg);
        opacity: 0.8;
        animation: pan-image 20s ease-in-out infinite;
      }

      &--1 {
        background-size: cover;
        background-repeat: no-repeat;

        background-image: url(/img/trillo-1x.webp);

        @media (min-resolution: 192dpi) and (min-width: 600px) {
          background-image: url(/img/trillo-2x.webp);
        }
      }

      &--2 {
        background-size: cover;
        background-repeat: no-repeat;

        background-image: url(/img/nextar-1x.webp);

        @media (min-resolution: 192dpi) and (min-width: 600px) {
          background-image: url(/img/nextar-2x.webp);
        }
      }

      &--3 {
        background-size: cover;
        background-repeat: no-repeat;

        background-image: url(/img/nartour-1x.webp);

        @media (min-resolution: 192dpi) and (min-width: 600px) {
          background-image: url(/img/nartour-2x.webp);
        }
      }

      &__active {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        transition: all 0.9s;
        transform: scale(1);
      }

      // &:not() {

      //     // justify-content: space-around;

      // }
    }
  }

  &__picture {
    // max-width: 100%;
    grid-column: 1 / span 1;
    margin-top: 1rem;

    position: relative;

    z-index: 2;

    min-height: 400px;
    border-radius: 16px;
    overflow: hidden;
    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
      padding: 1rem 2rem;
    }
  }

  &__img {
    // position: relative;

    width: 100%;
    height: 100%;
    border-radius: 16px;

    object-fit: contain;
    display: block;
    z-index: 100;
    border-radius: 16px;
    width: 100%;
    height: 130%;
    object-fit: contain;
    // border: 2px solid $color-secondary;
    display: block;
    position: absolute;
    border-radius: 16px;
    object-fit: contain;
    -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
  }

  &__content--dark {
    grid-column: 3 / span 1;
    margin-top: 1rem;
    // background-color: $color-onyx-grey;

    // row-gap: 1rem;
    padding: 2rem 4rem;
    display: grid;
    // align-content: center;
    justify-items: start;
    font-size: 2rem;
    row-gap: 1rem;
    overflow: hidden;
    //test

    overflow-x: hidden !important;
    color: $color-primary;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    border-radius: 16px;

    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
    }
  }

  &__content {
    grid-column: 3 / span 1;
    -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    margin-top: 1rem;
    background-color: $color-onyx-grey;
    padding: 2rem 4rem;
    display: grid;
    overflow-x: hidden !important;

    justify-items: start;

    row-gap: 1rem;

    //test

    color: $color-primary;
    border-radius: 16px;

    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
    }
  }

  &__description {
    font-family: "poppinsthin";
  }

  &__title {
    // grid-row: 1/span 1
  }

  &__type {
    font-family: "poppinsthin";
    font-weight: 100;
    font-style: italic;
  }

  &__tech {
    align-self: end;

    overflow: hidden;
    // grid-row: 3/span 1;
    // grid-column: 1;
  }

  &__links {
    // grid-row: -1;
    // grid-column: 1/span 2;
    // background-color: red;
    align-self: end;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.npm-project {
  margin-top: 5rem;
  grid-column: center-start / center-end;

  font-size: 2rem;

  &__links {
    font-style: italic;
  }

  &__github {
    grid-column: center-start / center-end;

    // align-self: center;
  }

  &__content {
    grid-column: center-start / center-end;
    margin-top: 1rem;
    background-color: $color-onyx-grey;

    // row-gap: 1rem;
    padding: 2rem 4rem;
    display: grid;
    // align-content: center;
    justify-items: start;
    font-size: 2rem;
    row-gap: 1rem;
    overflow: hidden;
    //test

    overflow-x: hidden !important;
    color: $color-primary;
    -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.9);
    -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.9);
    box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.9);
    border-radius: 16px;
    @media only screen and (max-width: $bp-small) {
    }
  }

  &__content--dark {
    grid-column: center-start / center-end;
    margin-top: 1rem;
    // background-color: white;

    // row-gap: 1rem;
    padding: 2rem 4rem;
    display: grid;
    // align-content: center;
    justify-items: start;
    font-size: 2rem;
    row-gap: 1rem;
    overflow: hidden;
    //test

    overflow-x: hidden !important;
    color: $color-primary;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    border-radius: 16px;

    @media only screen and (max-width: $bp-small) {
    }
  }
}
.header-text {
  color: red;
}
.header-text-new {
  color: lime;
}
.js-title {
  position: absolute;
  width: 100%;

  left: 0;
  // right: 0;
  top: 0;
  transform: translateY(-25%);
  // text-align: center;
  // font-size: 150px;
  // font-weight: bold;
}

.js-title:nth-child(1) {
  color: #1dffff;
}

.js-title:nth-child(2) {
  color: #fe0604;
  mix-blend-mode: color;
}
.npm-project-reverse-title {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: end;
}
.project-reverse {
  margin-top: 5rem;
  grid-column: center-start / center-end;
  display: grid;
  font-size: 2rem;
  border-radius: 16px;

  // grid-template-rows: repeat(6, 1fr);
  grid-template-columns: 48% 4% 48%;

  // column-gap: 5rem;

  @media only screen and (max-width: $bp-small) {
    grid-template-columns: 50% 50%;
  }

  &__picture {
    grid-column: 3 / span 1;

    position: relative;
    margin-top: 1rem;
    min-height: 400px;
    border-radius: 16px;

    overflow: hidden;
    // aspect-ratio: 1;
    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
      padding: 1rem 2rem;

      grid-row: 1;
    }
  }

  &__img {
    border-radius: 16px;
    width: 100%;
    height: 130%;
    object-fit: contain;
    // border: 2px solid $color-secondary;
    display: block;
    position: absolute;
    border-radius: 16px;
    object-fit: contain;
    -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
  }

  &__content {
    grid-column: 1 / span 1;
    margin-top: 1rem;
    background-color: $color-onyx-grey;
    border-radius: 16px;
    // row-gap: 1rem;
    padding: 2rem 4rem;
    display: grid;
    // align-content: center;
    justify-items: start;
    font-size: 2rem;
    row-gap: 1rem;
    overflow: hidden;
    //test

    overflow-x: hidden !important;
    color: $color-primary;
    -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);

    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
    }
  }

  &__content--dark {
    border-radius: 16px;
    grid-column: 1 / span 1;
    margin-top: 1rem;
    // background-color: $color-onyx-grey;

    // row-gap: 1rem;
    padding: 2rem 4rem;
    display: grid;
    // align-content: center;
    justify-items: start;
    font-size: 2rem;
    row-gap: 1rem;
    overflow: hidden;
    //test

    overflow-x: hidden !important;
    color: $color-primary;
    -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    border-radius: 16px;

    @media only screen and (max-width: $bp-small) {
      grid-column: 1 / -1;
    }
  }

  &__title {
  }

  &__type {
    font-family: "poppinsthin";
    font-weight: 100;
    font-style: italic;
  }

  &__tech {
    align-self: end;
  }

  &__links {
    align-self: end;
  }
}

.scroll-container {
  font-family: $font-primary;
  font-size: 1.2rem;

  // overflow-x: hidden !important;
  // white-space: nowrap
}

.scroll-text {
  max-width: 100%;
  // overflow-x: hidden !important;
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 20s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes pan-image {
  0% {
    background-position: 0 % 0%;
    background-size: 100%;
  }

  40% {
    background-position: 90% 100%;
    background-size: 100%;
  }

  100% {
    background-position: 0 % 0%;
    background-size: 100%;
  }
}

@keyframes pan-image-orgin {
  0% {
    background-position: 36% 42%;
    background-size: 200%;
  }

  20% {
    background-position: 30% 35%;
    background-size: 200%;
  }

  20.0001% {
    /* -- View 2 -- */
    background-position: 60% 85%;
    background-size: 500%;
  }

  40% {
    background-position: 49% 81%;
    background-size: 500%;
  }

  40.0001% {
    /* -- View 3 -- */
    background-position: 80% 42%;
    background-size: 300%;
  }

  60% {
    background-position: 84% 33%;
    background-size: 300%;
  }

  60.0001% {
    /* -- View 4 -- */
    background-position: 0% 0%;
    background-size: 300%;
  }

  80% {
    background-position: 15% 4%;
    background-size: 300%;
  }

  80.0001% {
    /* -- View 5 -- */
    background-position: 80% 10%;
    background-size: 300%;
  }

  100% {
    background-position: 72% 14%;
    background-size: 300%;
  }
}
.color-option-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.color-options {
  display: flex;
  justify-content: center;
  // gap: 20px;
  // margin-top: 20px;
  // flex-direction: row;
  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
}

.color-options label {
  display: flex;

  cursor: pointer;
}

.color-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 8px;
  transform: translateX(50%);
  border: 2px solid #333;
}

.color-options input[type="radio"] {
  display: none;
}

.color-options input[type="radio"]:checked + .color-circle {
  border-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.npm-color-custom {
  display: flex;

  flex-direction: row;
  font-family: $font-display;
  justify-content: space-around;
  align-content: center;
  gap: 10px;
  &__tc {
  }
  &__bg {
  }
}

.try-me-svg {
  .try-me-text {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }

  .arrow-shape {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  &:hover {
    .try-me-text {
      opacity: 0;
    }

    .arrow-shape {
      opacity: 1;
    }
  }
}
