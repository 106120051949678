.custom-btn {
  width: 130px;
  height: 40px;
  color: $color-secondary;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 2rem;
  font-family: $font-display;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.headingtest p {
  //   font-family: "wild_worldbold";
  // font-family: "wild_worldbold";
  font-size: 15.5vw;
  font-size: clamp(12px, 15.5vw, 250px);
  text-align: center;
  line-height: 0.67;
  margin: 0;
  text-align: center;
  color: $color-secondary;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: aquamarine;
  z-index: -10;
}
.headingtest {
  // background-color: red;
  // grid-column: center-start / center-end;
  position: absolute;
  top: 60vh;
  left: 50%;
  width: 100%;

  font-family: $font-display;

  // position: absolute;
  // width: 100%;
  // left: 25%;
  // color: currentColor;
  // opacity: 0;
  // position: absolute;

  // transform: translateX(-50%);
  // opacity: 0;

  // left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  display: flex;
  gap: 4rem;
  flex-direction: column;
}

.text-container {
  position: relative;
}

.text-container p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: transparent;
}

.text-container p:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: currentColor;
}
.text > p > div {
  background: linear-gradient(to right, $color-primary 50%, currentColor 50%);
  background-size: 200% 100%;
  background-position-x: 100%;
  color: transparent;

  -webkit-background-clip: text;
  background-clip: text;
  /*   margin-bottom: -250px; */
  margin-left: 50px;
  line-height: 1.2;
  /*   outline: 6px dotted teal; */
  font-size: 4vw;
}
.text-darkmode > p > div {
  background: linear-gradient(to right, $color-secondary 50%, currentColor 50%);
  background-size: 200% 100%;
  background-position-x: 100%;
  color: transparent;

  -webkit-background-clip: text;
  background-clip: text;
  /*   margin-bottom: -250px; */
  margin-left: 50px;
  line-height: 1.2;
  /*   outline: 6px dotted teal; */
  font-size: 4vw;
}

/* 12 */
.btn-12 {
  position: relative;
  right: 20px;
  bottom: 20px;
  border: none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}

.btn-12 span {
  background: $color-secondary;
  background: linear-gradient(0deg, $color-primary 0%, $color-primary 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-12 span:nth-child(1) {
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.btn-12:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.about-me__text {
  margin-top: 6rem;
  @media only screen and (max-width: $bp-small) {
    margin-top: 1rem;
  }
}
/* 16 */
.btn-16 {
  border: none;
  color: #000;
}

.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}

.btn-16:hover {
  color: #000;
}

.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-16:active {
  top: 2px;
}

.darkmode-text-bg {
  color: currentColor;
  background: $color-primary;
}
.regmode-text-bg {
  color: currentColor;
  background: $color-secondary;
}
