.carousel {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  color: inherit;
}

.cards {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  transition: all 0.5s;
}

.card {
  position: relative;
  width: calc(100% / 3);
  height: 100%;
  background-color: transparent;
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 20px;
  transition: all 0.5s;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  // @media only screen and (max-width: 400px) {
  //     object-fit: contain;
  // }
}

.card h2 {
  margin-top: 20px;
  font-size: 20px;
}

.card p {
  margin-top: 10px;
  font-size: 16px;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: $color-secondary;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  // background-color: red;
  font-size: 4rem;

  transition: all 0.5s;
}

.next:hover {
  transform: translate(20%, -50%) scale(1.3);
}

.prev:hover {
  transform: translate(-20%, -50%) scale(1.3);
}

.prev i,
.next i {
  display: inline-block;
  z-index: 100;
}

.prev {
  left: 0%;
  transform: translate(-20%, -50%);
}

.next {
  right: 0;
  transform: translate(20%, -50%);
}
