/* ///////////////BLOB////////// */



#blob {
    // background-color: white;
    // background-color: white;
    height: 300px;
    width: 300px;
    position: absolute;
    left: 0%;
    top: 0%;
    translate: -50% -50%;
    border-radius: 50%;
    background: linear-gradient(to right, aquamarine, mediumpurple);
    animation: rotate 20s infinite;
    z-index: 1;
}

#blur {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    -webkit-backdrop-filter: blur(200px);
    backdrop-filter: blur(200px);
}








/* ////// ANIMATIONS //////  */


@keyframes rotate {
    from {
        rotate: 0deg;
    }

    50% {
        scale: 1 1.5;
    }

    to {
        rotate: 360deg;
    }
}