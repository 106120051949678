.menu-container {
  display: grid;
  // grid-template-columns: 10vw 10vw
  // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  // grid-template-rows: minmax(8rem, max-content)1fr 7rem;
  grid-template-columns:
    [full-start] minmax(6rem, 1fr) [center-start] repeat(
      12,
      [col-start] minmax(min-content, 10rem) [col-end]
    )
    [center-end] minmax(6rem, 1fr) [full-end];
  grid-template-rows: minmax(min-content, 10vh) 1fr minmax(min-content, 10vh);
  opacity: 0;
  // position: absolute;

  color: $color-primary;
  // border-radius: 10%;
  // width: 100vw;
  // min-width: 100%;
  // height: 100vh;
  min-height: 100%;
  visibility: hidden;
  background-color: rgba($color-secondary, 0.5);
  background-color: $color-secondary;
  transition: opacity 0.7s;
  // transform: scaleY(1);

  transform-origin: bottom;
  // border: 1px solid $color-primary;

  z-index: 10;
}

.mid {
}

.menu-open {
  opacity: 1;
  visibility: visible;
  z-index: 10;
  position: relative;
  // transform: translateY(0%) scaleY(1);
  // background-color: yellow;
}

.navigation {
  display: flex;

  // flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  // background-color: red;
  grid-column: center-start / center-end;

  &__link:link,
  &__link:visited {
    text-decoration: none;
    color: currentColor;
  }

  &__name {
    z-index: 101;
    cursor: pointer;
    font-size: 10rem;
  }

  &__menu {
    font-family: $font-display;
    font-size: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    // background-color: green;
    grid-column: 1/-1;
  }

  &__item {
    font-family: $font-display;

    // font-family: 'poppinsthin';
    text-transform: uppercase;
    font-size: 5rem;
    cursor: pointer;
    // // background-color: aqua;
    // position: relative;
    // overflow: hidden;
    // transition: all .9s;
    // transform: translateY(0);
    transition: all 0.7s;
    transform: translateY(0%) rotateY(0) perspective(0);

    sup {
      font-size: 2.5rem;
      font-family: "poppinsthin";
    }

    // &:hover>&--1 {

    //     top: 0%;

    // }

    &--1 {
      transition: all 0.7s;
      // background-color: currentColor;
      z-index: 2001;
      height: 100%;
      position: absolute;
      // width: 100%;
      top: 0;
      right: -100%;
      font-size: 5rem;
      sup {
        font-size: 2.5rem;
        font-family: "poppinsthin";
      }
    }

    &--2 {
      transition: all 0.7s;
      // background-color: currentColor;
      z-index: 2001;
      height: 100%;
      position: absolute;
      // width: 100%;
      top: 0;
      right: -100%;
      font-size: 5rem;
      sup {
        font-size: 2.5rem;
        font-family: "poppinsthin";
      }
    }

    &--3 {
      transition: all 0.7s;
      // background-color: currentColor;
      z-index: 2001;
      height: 100%;
      position: absolute;
      // width: 100%;
      top: 0;
      right: -100%;
      font-size: 5rem;
      sup {
        font-size: 2.5rem;
        font-family: "poppinsthin";
      }
    }

    &--4 {
      transition: all 0.7s;
      // background-color: currentColor;
      z-index: 2001;
      height: 100%;
      position: absolute;
      // width: 100%;
      top: 0;
      right: -100%;
      font-size: 5rem;
      sup {
        font-size: 2.5rem;
        font-family: "poppinsthin";
      }
    }
  }
}

.nav-wrapper {
  font-family: $font-display;

  // font-family: 'poppinsthin';
  text-transform: uppercase;
  cursor: pointer;
  // background-color: aqua;
  position: relative;
  overflow: hidden;
  // transition: all .7s;

  &:hover > .navigation__item {
    transform: translateX(-100%);
  }

  &:hover > .navigation__item--1 {
    right: 0%;
  }

  &:hover > .navigation__item--2 {
    right: 0%;
  }

  &:hover > .navigation__item--3 {
    right: 0%;
  }

  &:hover > .navigation__item--4 {
    right: 0%;
  }
}

.socials {
  grid-column: center-start / center-end;
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // background-color: red;

  font-family: $font-display;

  &__item {
    &--1 {
      font-size: 5rem;
    }

    &--2 {
      font-size: 5rem;
    }
  }
}

.nav-reveal {
  animation: reveal;
}

@keyframes reveal {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;

  --white: #ffffff;
  --greyLight-1: #e4ebf5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;
}

$shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
  -0.2rem -0.2rem 0.5rem var(--white);
$inner-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
  inset -0.2rem -0.2rem 0.5rem var(--white);

.switch {
  /*   grid-column: 1 / 2; */
  // display: grid;
  // grid-template-columns: repeat(2, min-content);
  grid-gap: 3rem;
  justify-self: center;
  // transform: rotate(-90deg);

  input {
    display: none;
  }

  &__1 {
    width: 6rem;

    label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 3rem;
      box-shadow: $shadow;
      background: $color-primary;
      position: relative;
      cursor: pointer;
      border-radius: 1.6rem;

      &::after {
        content: "";
        position: absolute;
        left: 0.4rem;
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 50%;
        background: $color-secondary;
        background-image: url(/img/moon.svg);

        background-size: cover;
        transition: all 0.4s ease;
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: $color-secondary;

        opacity: 0;
        transition: all 0.4s ease;
      }
    }
  }

  & input:checked {
    & ~ label {
      background-color: $color-secondary;

      &::before {
        opacity: 1;
      }

      &::after {
        left: 57%;
        background: $color-primary;

        background-image: url(/img/sun.svg);
        background-size: cover;
      }
    }
  }
}

.nav-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  // background-color: red;
  backdrop-filter: blur(10px);
  // background-color: rgba($color: #ffffff, $alpha: 1.0);
  z-index: 9;
  transition: height 0.4s;
}

.fixed-nav {
  backdrop-filter: blur(10px);
  // position: relative;
  // grid-column: center-start / center-end;
  position: fixed;
  background-color: rgba($color-secondary, 0.5);
  width: 120px;
  height: 60px;
  border-radius: 5%;
  left: 50%;
  z-index: 100;
  max-width: 1200px;
  transform: scaleX(1) translateX(-50%);
  bottom: 3rem;
  transition: height 0.4s, width 0.4s 0.2s, left 0.2s, transform 0.2s 0.4s;
  overflow: hidden;

  @media only screen and (max-width: 500px) {
    border-radius: 7%;
  }

  // &::after {
  //     content: "menu";
  //     position: absolute;
  //     left: 50%;
  //     color: $color-primary;
  //     top: 50%;
  //     transform: translate(-50%, -50%);
  //     font-family: $font-display;
  //     font-size: 4rem;
  // }
}

.text-nav {
  position: absolute;

  left: 50%;
  color: $color-primary;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: $font-display;
  cursor: pointer;
  font-size: 4rem;
  transition: all 0.9s;
}

.text-nav-open {
  visibility: hidden;
  top: 0;
  opacity: 0;
  transition: all 0.9s;

  // right: 5%;
  // transform: translate(0%, 0%);
}

.close-nav {
  fill: currentColor;
  cursor: pointer;
  color: currentColor;

  @media only screen and (max-width: $bp-medium) {
    transform: translateX(25%);
  }
}

.nav-open {
  // grid-column: center-start / center-end;
  // left: 600px;
  // left: 0;

  height: 90%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1200px;
  z-index: 1000;
  // background: green;
  transition: width 0.4s, height 0.9s 0.2s, left 0.4s;
}

/* ///////////////BLOB menu////////// */

#menu-blob {
  // background-color: white;
  height: 300px;
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 50%;
  background: linear-gradient(to right, aquamarine, mediumpurple);
  animation: rotate 20s infinite;
  z-index: 1;
}

#menu-blur {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  backdrop-filter: blur(200px);
}

/* ////// ANIMATIONS //////  */

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  50% {
    scale: 1 1.5;
  }

  to {
    rotate: 360deg;
  }
}
