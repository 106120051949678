.degrees {
  grid-column: center-start / center-end;

  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  //   background-color: red;

  &__item {
    span {
      display: block;
      width: 100%;
    }

    border-bottom: 1px solid currentColor;
    border-top: 1px solid currentColor;
    width: 100%;
    height: 20vh;
    font-size: 40px;
    font-family: $font-display;
    // transition: all 0.9s;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    cursor: pointer;

    @media only screen and (max-width: $bp-small) {
      flex-direction: column;
    }
  }

  &__img {
    // width: 0;
    // transition: all 0.9s;
    // margin-right: 2rem;
    // justify-self: end;

    -webkit-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    -moz-box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
    box-shadow: 0px 10px 12px -6px rgba(32, 30, 30, 0.3);
  }

  &__active {
    width: 190px;
  }
}
